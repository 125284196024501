// *
// * JUMEDIA Webagentur GmbH
// * Bammatter Theme
// *


//
// COMPONENTS/LINKS
// --------------------------------------------------

// VARIABLES
$btn-font-family: $base-font-family-sans-serif;
$btn-font-weight: 700;

$btn--secondary-color:					$color-btn;
$btn--secondary-color--dark:		darken($color-btn,15%);

$btn--primary-highlight-bg:			$color-text;
$btn--primary-highlight-text: 	$color-text--inverted;

$btn--secondary-highlight-text:	$color-text--inverted;

// USED FROM _variables.scss
// $base-spacing-unit--xs, --sm, --xl
// $base-border-radius
// $base-border-width
// $base-border

// --------------------------------------------------

@import "../../../nutshell/scss/components/links";

// --------------------------------------------------

%button {
  font-family: $btn-font-family;
  font-weight: $btn-font-weight;
}

button.submit, .submit {
  border-radius: 4px;
  padding: 0.2rem 2.5rem;
  font-weight: 400;
}



// btn--primary
a.btn--primary,
span.btn--primary,
button.btn--primary {
	p & {
		margin-bottom: 0;
		text-decoration: none;
	}
}

// [1] button used in a .band--highlight container
div.btn--primary {
  
  a {
    .band--highlight & { // [1]
      background: $btn--primary-highlight-bg;
      color: $btn--primary-highlight-text;
    }
  }
}

a.btn--secondary,
span.btn--secondary,
button.btn--secondary {
  border-radius: $base-border-radius;
  color: $btn--secondary-color;
  border: $base-border-width solid currentColor;
  background-color: transparent;
  
  &:hover {
    color: $btn--secondary-color--dark;
    border-color: $btn--secondary-color--dark;
    background-color: transparent;
  }
  
  .band--highlight & {
    color: $btn--secondary-highlight-text;
    border-color: $btn--secondary-highlight-text;
    
    &:hover {
      opacity: 0.5; 
    }
  }
}

div.btn--secondary a {
  border-radius: $base-border-radius;
  color: $btn--secondary-color;
  border: $base-border-width solid currentColor;
  
  &:hover {
    color: $btn--secondary-color--dark;
    border-color: $btn--secondary-color--dark;
  }
  
  .band--highlight & {
    color: $btn--secondary-highlight-text;
    border-color: $btn--secondary-highlight-text;
    
    &:hover {
      opacity: 0.5; 
    }
  }
}

div.btn--warenkorb {
	position: fixed;
	right: 0;
	top: $base-spacing-unit--xl;
	transform: rotate(-90deg) translateY($base-spacing-unit--xs);
	transform-origin: bottom right;
	transition: transform 0.1s;
	
	&:hover {
		transform: rotate(-90deg) translateY(0);
	}
	
	a {
		padding: $base-spacing-unit--xs $base-spacing-unit--sm $base-spacing-unit--sm $base-spacing-unit--sm;
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
		border: $base-border;
		border-bottom: 0;
	}
}

.ce_hyperlink a {
	font-family: $base-font-family-serif;
	font-size: 1.6rem;
}

// back
.link--back { 
	position: absolute;
	top: 2rem;
	
	@include media-query(screen-lg) {
	top: 4.4rem;
	}
	
	a {
		font-size: 5rem;
		font-family: $base-font-family-serif;
		font-weight: 400;
	}
	
	
}

.back {
 	border: none;
 	position: absolute;
	top: 0.8rem;
	
	@include media-query(screen-lg) {
		top: 3rem;
	}
  
	.back a {
    	text-decoration: none; 
    }
    }
    .back a:before {
      font-size: 5rem;
	  font-family: $base-font-family-serif;
	  font-weight: 400;      
	  display: inline-block;
      content: "<";
      text-indent: 0;
     }


.mod_iso_productreader .back {
 	border: none;
 	position: absolute;
	top: 4rem;
	
	@include media-query(screen-lg) {
		top: 3rem;
	}
}
