// *
// * Erdmann & Freunde - Nutshell
// * BASE Contao Framework
// * erdmann-freunde.de/nutshell/
// *
// * Lizenziert unter MIT OPEN SOURCE 
// *
  
//
// VARIABLES
// --------------------------------------------------

// General Colors
$color-default:     #ff5636 !default;
$color-gray-dark:   #222    !default;
$color-gray:        #555    !default;
$color-gray-light:  #ccc !default;

$color-brand:       $color-default !default;
$color-highlight:   $color-brand !default;

// Page
$color-page-background:   #fff !default;
$color-text:              $color-gray-dark !default;
$color-text--inverted:    $color-page-background !default;

// Links
$color-links: $color-highlight !default;

// Buttons
$color-btn:       $color-links !default;
$color-btn-text:  #fff !default;

// Base Font Settings
$base-font-size--xs:      0.875rem !default;
$base-font-size:          1rem !default;
$base-font-size--xl:      1.125rem !default;

$base-line-height:        1.625 !default;

$base-font-family:        "Helvetica", "Helvetica Neue" !default;

// Base Spacing Units
$base-spacing-unit:           1rem  !default;

$base-spacing-unit--xs:       $base-spacing-unit / 4 !default;
$base-spacing-unit--sm:       $base-spacing-unit / 2 !default;

$base-spacing-unit--lg:       $base-spacing-unit * 2 !default;
$base-spacing-unit--xl:       $base-spacing-unit * 4 !default;

// deprecated
$base-spacing-unit--small:    $base-spacing-unit--sm !default;
$base-spacing-unit--large:    $base-spacing-unit--lg !default;

// Base Border Settings
$base-border-radius:          4px !default;
$base-border-width:           1px !default;
$base-border-color:           $color-gray-light !default;

$base-border:                 $base-border-width solid $base-border-color !default;
