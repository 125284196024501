// *
// * JUMEDIA Webagentur GmbH
// * Bammatter Theme
// *

// VARIABLES
// General Colors
$color-gray-dark:   			#221E1F;
$color-gray:					#6F7070;
$color-gray-light:  			#DBCDC8;

$color-red:      				#CA242B;

$color-tint:        			$color-gray-light;

$color-brand:       			$color-red;
$color-highlight:				$color-brand;

// Page
$color-page-background:   		#fff;
$color-text:              		$color-gray-dark;

// Links
$color-links:     				$color-highlight;

// Buttons
$color-btn:       				$color-links;
$color-btn-text:  				#fff;


// Base Font Settings
$base-font-size:          				1rem;
$base-line-height:        				1.625;

$base-font-size--xs:      				0.95rem;

$base-font-size--lg:      				1.125rem;
$base-font-size--xl:      				1.25rem;

$base-font-family-serif:          "Domine", Georgia, serif;
$base-font-family-sans-serif:     "Roboto", Helvetica, sans-serif;

$base-font-family:                $base-font-family-sans-serif;

// Base Spacing Units
$base-spacing-unit:		1.625rem;


// Base Border Settings
$base-border-radius:	4px;
$base-border-width:   2px;
$base-border-color:   currentColor;


// ADD YOUR OWN VARIABLES HERE
// Bands
$color-band-highlight-background:     $color-highlight;
$color-band-tint-background:          $color-tint;
$color-band-dark-text:                #fff;
$color-band-dark-background:          #221E1F;
$color-band-dark-background-gradient: radial-gradient(circle at 0% 0%, $color-band-dark-background 0%, darken($color-band-dark-background, 10%) 100%);

$base-img-shadow: 				0 0 5px rgba(0, 0, 0, 0.1), 0 2px 5px rgba(0, 0, 0, 0.25) !default;
$base-overlay-background: rgba(0,0,0,0.7);

// IE Support
$ie9-support: true;


// --------------------------------------------------

@import "../../../nutshell/scss/variables";

// --------------------------------------------------
