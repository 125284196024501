// *
// * JUMEDIA Webagentur GmbH
// * Bammatter Theme
// *

//
// BASE/LAYOUT
// --------------------------------------------------

// VARIABLES
$header-color-background:                     $color-band-dark-background;
$header-color-background--inverted:           $color-band-dark-background !default;
$header-color-background--inverted-gradient:  $color-band-dark-background-gradient !default;
$header-color-text--invert:                 	#fff;
$header-height:                     			3rem;

// USED FROM _layout.scss (nutshell)
// * $header-color-text [$color-text]

// USED FROM _variables.scss
// $base-spacing-unit, --sm, --lg
// $color-band-highlight-background
// $color-band-tint-background
// $color-band-dark-background-gradient
// $color-band-dark-background
// $color-band-dark-text

// --------------------------------------------------

@import "../../../nutshell/scss/base/layout";

// --------------------------------------------------

// nav--mobile--active
html.html--fixed {
  @include media-query(screen-offcanvas-max) {
    overflow-y: hidden;
    position: relative;
  }
}

// [1] sticky footer via flexbox, see https://github.com/philipwalton/solved-by-flexbox/blob/master/assets/css/components/site.css
body {
	height: 100vh; // [1]
}

// #wrapper
// [1] sticky footer via flexbox 
[id="wrapper"] {
  display: flex; // [1]
  flex-direction: column; // [1]
  height: 100%; // [1]
}

// #header
[id="header"] {
  background: $header-color-background;
  position: fixed;
  z-index: 1001;
  left: 0;
  right: 0;
  bottom: 0;
  height: $header-height;
  flex: none;
  color: $header-color-text--invert;
  margin-left: 0;
  
  @include media-query(screen-lg) {
	  margin-left: -1rem;
  }
  
  @include media-query(screen-xl) {
	  margin-left: -2rem;
  }
}

.down #header, .headerfix,
.header--relative [id="header"]{
   position: fixed;
   top:0;
   height: $header-height;
}

.header--relative #container{
	padding-top: 3rem;
}

.background--light #container{
	background-color: $color-gray-light;
}

.background-main--light #container {
	background-color: $color-gray-light;
}

// #container
// [1] sticky footer via flexbox 
// [2] IE10: sticky footer, fix gap between #container and #footer 
[id="container"] {
  flex: 1 0 auto; // [1]
  overflow: hidden; // [2]

}

// #main
.mod_article {
	padding-top:    $base-spacing-unit;
  padding-bottom: $base-spacing-unit;
  
  @include media-query(screen-lg) {
    padding-top:    $base-spacing-unit--lg;
    padding-bottom: $base-spacing-unit--lg;
  }
  
  @include media-query(screen-xl) {
    padding-top:    $base-spacing-unit--lg * 1.25;
    padding-bottom: $base-spacing-unit--lg * 1.25;
  }
  
  .inside {
	  > .last {
 	    margin-bottom: 0;
	  }
  }
}

.article--full {
  @include wrapper-max(none);
  
  padding: 0;
}

.article--80rem{
	max-width: 80rem;
	padding: 0;
	
	div {
		padding: 0;
	}
}

// #footer
[id="footer"] {	
	flex: none;
	background-color: $color-band-dark-background;
	color: #A0A0A0;
	font-size: 0.8rem;
	
	  @include media-query(screen-offcanvas-max) {
	  font-size: 1rem;
  }
	
	a {
		color: #A0A0A0;
	}

  .inside {
    padding-top: $base-spacing-unit--sm;
    padding-bottom: $base-spacing-unit--sm;
    max-width: 75em;
  }
}

.footer_wrapper .mod_article{
	  max-width: 75em;
	  padding-bottom: 0;
}

.background--light [id="footer"]{
	background-color: $color-gray-light;
}

// band
.band {
  @include wrapper-max(none);

  padding-left: 0;
  padding-right: 0;
  position: relative;
  
  .inside {
    @include wrapper-max();
    @include padding-default();
  }
}

.band--highlight {
  background-color: $color-band-highlight-background;
  color: #fff;
  
  a {
    color: inherit;
  }
}

.band--tint {
  background-color: $color-band-tint-background;
}

.band--dark {
  background: $color-band-dark-background;
  background: $color-band-dark-background-gradient;
  color: $color-band-dark-text;
}
