// *
// * Erdmann & Freunde - Nutshell
// * BASE Contao Framework
// * erdmann-freunde.de/nutshell/
// *
// * Lizenziert unter MIT OPEN SOURCE 
// *

//
// COMPONENTS/DOWNLOADS
// --------------------------------------------------

// VARIABLES
$download-link-color:   inherit !default;
$download-link-decoration: none !default;

// USED FROM _variables.scss

// --------------------------------------------------

.ce_download,
.ce_downloads {
  margin-bottom: 1rem;
  
  .mime_icon {
    display: inline-block;
    vertical-align: middle;
  }
  
  a {
    display: inline-block;
    color: $download-link-color;
    text-decoration: $download-link-decoration;
    
  }
}

.ce_downloads {
  @include navigation;
}