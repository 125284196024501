// *
// * JUMEDIA Webagentur GmbH
// * Bammatter Theme
// *

//
// COMPONENTS/SHOP
// --------------------------------------------------
// .mod_iso_productreader
.mod_iso_productlist {
	
	.product {
		width: 100%;
		float: left;
		margin-left: 0;
		margin-right: 0;
		margin-bottom: $base-spacing-unit;		
		
		@include media-query(screen-sm) {
			width: 48%;
			margin-left: 1%;
			margin-right: 1%;
		}
		
		@include media-query(screen-lg) {
			width: 31%;
			margin-left: 1%;
			margin-right: 1%;
		}
		
		
		div .caption {
			position: relative;
			visibility: hidden;
			padding: 2rem;
			background: $color-gray-dark;
			margin-top: -6.2rem;
			width: 100%;	
			
			h2 {
				font-size: 1rem;
				color: #fff;
				font-weight: 400;
			}	
		}
		
		div:hover .caption {
			visibility: visible;
		}
	}
	
}



// .mod_iso_productreader
.mod_iso_productreader {
	.headline--center, .nav--shop {
		display: none;
	}
	
	h1{
		@include media-query(screen-xs) {
			color: $color-red;
			display: inline; 
			text-transform: uppercase;
			border: none;
			   -moz-hyphens: auto;
			   -o-hyphens: auto;
			   -webkit-hyphens: auto;
			   -ms-hyphens: auto;
			   hyphens: auto; 
	
			&:before {
				content: "";
				display:block;
				margin: 1em 0;
			}
	
			&:after {
				content: "";
				display:block;
				margin: 0 auto;
				border-bottom: 8px solid $color-red;
				padding-bottom: 0.6rem;
				width:380px;
				margin-bottom: 1.5rem;
			}
		}
		
		@include media-query(screen-lg) {
			display: inline;
			border-bottom: 8px solid #CA242B;
			padding-bottom: 0.8rem;
			text-transform: uppercase;
			
			&:before {
			}
	
			&:after {
				border-bottom: none;

			}
		}
		
	}
	
	.description, .baseprice {
		border-bottom: 1px solid $color-gray-dark;
		padding-bottom: $base-spacing-unit;
		margin-bottom: $base-spacing-unit;
	}
	
	.baseprice {
		margin-top: $base-spacing-unit--xs;
	}
	
	.price {
		text-align: right;
		font-family: $base-font-family-serif;
		font-size: 1.2rem;
		
		label {
			float: left;
			font-family: $base-font-family-sans-serif;
			font-size: $base-font-size;
		}
	}
	
	.options {
		label {
			display: block;
		}
		
		.widget-select {
			margin-bottom: 0;
		}
		
		select.select {
				margin-left: 0;
				 -webkit-appearance: none;
				 -moz-appearance: none;
				 appearance: none;
				 border-radius: 4px;
				 font-size: 1rem;
				 padding: 0.5rem;
				 border: 1px solid #a5a5a5;
				 width: 100%;
				 background-image: url(/files/bammatter/src/img/select-arrow.png);
				 background-position: right;
				 background-repeat: no-repeat;
				 
			option {
				font-size: 1rem;
			}
		}
		
		select::-ms-expand {
			display:none;
		}
	}
	
	.submit_container {
		margin-top: $base-spacing-unit;
		
		.quantity_container {
			width: 50%;
			float: left;
		}
		
		input.submit {
			width: 46%;
			float: right;
			margin-top: 2rem;
			padding: 0.4125rem 1.625rem;
			margin-left: 1rem;
		}
	}

}

.win.ie8 .mod_iso_productreader select,
.win.ie9 .mod_iso_productreader select {
    background-image: none;
}

// .mod_iso_messages

body #iso_ajaxBox {
    border: 1px solid #ccc;
    font-size: inherit;
}

body #iso_ajaxBox {
    box-sizing: border-box;
    font-family: $base-font-family;
    left: 50%;
    max-width: 640px;
    padding: 40px 70px;
    transform: translate(-50%, 0px);
    width: auto;
}

#iso_ajaxBox .iso_confirm {
    background: none;
    padding-left: 0;
}

#iso_ajaxBox .mod_iso_messages .iso_confirm {
    color: $color-gray-dark;
    display: block;
    font-size: 1.3em;
    line-height: 1.2;
	text-align: center;
}

#iso_ajaxBox .mod_iso_messages .button-wrapper::after {
    clear: both;
    content: " ";
    display: table;
}
#iso_ajaxBox .mod_iso_messages .button-wrapper {
    display: block;
    margin-top: 60px;
}

#iso_ajaxBox .mod_iso_messages .button--left {
	min-width: 210px;
    
    @include media-query(screen-lg) {
    	float: none;
  	}
  
  	@include media-query(screen-xl) {
    	float: left;
  	}
}

#iso_ajaxBox .mod_iso_messages .button--right {
		   min-width: 210px;
     @include media-query(screen-lg) {
    	float: none;
    	margin-left: 0;
  	}
  
  	@include media-query(screen-xl) {
    	float: right;
    	margin-left: 10px;
  	}
}

/* mod_iso_cart
   ========================================================================== */
.mod_iso_cart,
.mod_iso_checkout,
.mod_iso_orderdetails {

	table {
		width: 100%;
		border: none;
	
		td, th {
			border: none;
			@include media-query(screen-offcanvas-max) {
				padding: 0;
			}
		}
		
		thead {
			background: rgba(255, 255, 255, 0.2);
		
			.productprice,
			.sumprice {	
				text-align: right;
			}
		}
	}
	
	tbody {
	
			ul {
				list-style-type: none;
				margin-left: 0;
			}
		
			input {
				@include media-query(screen-offcanvas-max) {
					width: 50%;
					margin-top: $base-spacing-unit--sm;
					margin-bottom: $base-spacing-unit--sm;
				}
			}
			
			tr {
				@include media-query(screen-offcanvas-max) {
					border-bottom: 1px solid rgba(255, 255, 255, 0.4);
					padding-bottom: $base-spacing-unit--sm;
				}
			}
			
			.actions {
				@include media-query(screen-offcanvas-max) {
					margin-top: $base-spacing-unit--sm;
				}	
			}
	}

	.product .col_first {
    	width: 160px;
	}
	
	tr.product td {
		border-bottom: 1px solid rgba(255, 255, 255, 0.4);
		padding-bottom: 10px;
		padding-top: 10px;
		@include media-query(screen-offcanvas-max) {
				border: none;
				padding: 0;
			}	
	}
	
	.tax {
		display: none;
	}

	.image .image_container {
    	width: 120px;
	}

	.name {
    	padding-left: 40px;
		padding-right: 40px;
		width: 100%;
		@include media-query(screen-offcanvas-max) {
				padding: 0;
						}
	}

	.price {
    	min-width: 140px;
		width: 140px;
		text-align: right;
			@include media-query(screen-offcanvas-max) {
				width: 100%;
				text-align: left;
			}
	}

	tfoot {
		 .subtotal {
		 	display: none;
		}
		
		.total {
			font-weight: 700;
		}
		
		.foot_2 {
			.col_0.col_first,
			.col_3.price {
				display: block;
				
				@include media-query(screen-offcanvas-max) {
					display: none;
				}
			}
		}
	}

	.remove i.fa-times::before {
		font-family: fontAwesome;
		content: '\f00d';
	}

	.submit_container {
		margin-top: 20px;
		float: right;
			@include media-query(screen-offcanvas-max) {
				float: none;
			}
	
		input.button_checkout {
			float: right;
			margin-right: 45px;
		}
	}
}

// mod_iso_checkout
.mod_iso_checkout {
	.steps {
		margin-bottom: 4rem;
		color: $color-gray;
		
			@include media-query(screen-offcanvas-max) {
				margin-bottom: 1rem;
			}
		
			a {
				text-decoration: none;
			}
	
			ol {
				display: flex;
				padding: 0;
				max-width: 40rem;
			}

			li {
				width: 100%;
				@include media-query(screen-offcanvas-max) {
						font-size: 0.9rem;
					}
				
				&:after {
					font-family: fontAwesome;
					content: '\f054';
					font-size: 1rem;
					margin-left: 0.8rem;
					position: relative;
					top: 0.1rem;
					
					@include media-query(screen-offcanvas-max) {
						margin-left: 0.2rem;
						font-size: 0.8rem;
						top:0;
					}
				}
			}
			
			li.last::after {
				content: '';
			}

			.active.active,
			li.passed {
				color: $color-red;
			}
	}
	
	
	
	.review {
		.info_container {
				margin-bottom: $base-spacing-unit;
				
				a.edit {
					float: right;
				}
		}
	}
	
	.shipping fieldset span {
		display: inline;
	}
	
	.orderconditions {
		border-top: 1px solid rgba(255, 255, 255, 0.4);
		padding-top: $base-spacing-unit;
		
		.widget-calendar {
		label {
			display: block;
		}
		
		input {
			width: 50%;
			display: inline-block;
		}
		
		img {
			display: none;
		}
		}

	}
	
	.paymentmethod {
		fieldset span {
			display: block;
		}
	}
	
}

.mod_iso_orderdetails {
	.info_container {
		margin-bottom: $base-spacing-unit;
	}
	
	.info_container.first {
		margin-top: $base-spacing-unit;
	}
	
	
	h2 {
		padding-bottom: $base-spacing-unit--sm;
	}
	
	.order_status {
		display: none;
	}
		
}

