// *
// * Erdmann & Freunde
// * SOLO Contao Theme
// * erdmann-freunde.de/themes/solo/
// *

//
// COMPONENTS/ACCORDION
// --------------------------------------------------

// VARIABLES
$accordion-padding:       $base-spacing-unit--sm;
$accordion-background:    transparent;
$accordion-border-radius: $base-border-radius !default;

$toggler-font-size:       24px;
$toggler-background:      $color-highlight;
$toggler-color:           $color-text--inverted;
$toggler-spacing:         $base-spacing-unit--xs;
$toggler-border:          2px solid transparent;
$toggler-padding:         $base-spacing-unit--xs $base-spacing-unit--sm;
$toggler-icon-size:       1.25 !default;

$toggler-font-family:     $base-font-family-sans-serif;
$toggler-font-weight:     700;

// USED FROM _variables.scss

// --------------------------------------------------

@import "../../../nutshell/scss/components/accordion";

// --------------------------------------------------

// default accordion
.ce_accordion {
  .toggler {
    font-family: $toggler-font-family;
    font-weight: $toggler-font-weight;
    position: relative;
    border-radius: $accordion-border-radius;
    border: $toggler-border;
    padding: $toggler-padding;
    vertical-align: middle;
    transition: border-radius 0.3s;
  }
  
  .ui-accordion-header-active {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  
  // [1] define size for plus/cross
  .ui-accordion-header-icon {
    transition: transform 0.3s;
    float: right;
    display: inline-block;   
    transform: scale($toggler-icon-size); // [1]
    
    &:before {
      content: "+";
    }
  }
  
  .ui-icon-triangle-1-s {
    transform: rotate(45deg) scale($toggler-icon-size);
  }
  
  .ce_text:last-child {
    margin-bottom: 0;
  }
}