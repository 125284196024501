// *
// * Erdmann & Freunde
// * SOLO Contao Theme
// * erdmann-freunde.de/themes/solo/
// *

//
// COMPONENTS/FORMS
// --------------------------------------------------

// VARIABLES

// USED FROM _variables.scss

// --------------------------------------------------

@import "../../../nutshell/scss/components/forms";

// --------------------------------------------------

// PUT YOUR OWN FORM STYLES HERE
/*.widget-explanation {
  margin-bottom: $base-spacing-unit;
}  */

input.radio,
input.checkbox {
	vertical-align: top;
	font-weight: bold;
}

.widget-radio--listlarge span {
	margin-bottom: 1rem;
}

input.radio + label {
	display: inline!important;
	font-weight: bold;
	
	> span {
			font-weight: 400;
		}
}

input.checkbox + label {
	font-weight: bold;
	> span {
			font-weight: 400;
		}
}

.widget-radio--list {
	.radio_container {
		> span {
			display: block;
		}
	}
}

.widget-checkbox--list {
	label {
		margin-bottom: 0;
	}
	
	.checkbox_container {
		> span {
			display: block;
		}
	}
}

input.text,
input.captcha,
textarea {
  display: block;
  width: 100%;
  padding: 0.5rem;
  border-radius: none!important;
  border: 1px solid #a5a5a5;
}

.error {
	color: $color-red;
}
// Aperohäppchen Anzahl
#ctrl_260 {
	width: 60%;
	
	@include media-query(screen-offcanvas-max){
		width: 100%;
	}
}

legend {
	text-transform: uppercase;
	
	> span {
		text-transform: none; font-weight: 400;
	}
}

.mod_iso_checkout input.radio + label {
	margin-right: 0;
}
