// *
// * JUMEDIA Webagentur GmbH
// * Bammatter Theme
// *

//
// COMPONENTS/NAVIGATION
// --------------------------------------------------

// VARIABLES
  $color-navigation-main--active:   	$color-brand;
  $nav--main__item-padding:         	$base-spacing-unit--sm;
  $nav--main__item-padding--sm:     	$base-spacing-unit--xs;
  $nav--main-font:                  	$base-font-family-sans-serif;
  $nav--main-font-weight:           	700;
  
  $submenu-background:								$base-overlay-background;
  
  $nav-mobile-color-background:       $base-overlay-background;
  $nav-mobile-number-of-menu-points:  4;
  
  $menu-icon-width:										1.25rem;
  $menu-icon-stroke:									2px;
  

// USED FROM _variables.scss
// $color-text--inverted
// $base-spacing-unit, --xs, --sm
// $base-border
// $base-font-family-sans-serif

// USED FROM _layout.scss
// $header-height

// USED FROM _grid.scss
// $grid__gutter--half

// --------------------------------------------------

@import "../../../nutshell/scss/components/navs";

// --------------------------------------------------

// Main Navigation

// [1] IE9 needs this to make the whole anchor clickable and lets the dropdown-navigation work
// [2] 9999px make sure, the max-height is bigger than the actual submenu height, percentage will reference the header height
.nav--main {
  line-height: 1em;
  font-family: $nav--main-font;
  font-weight: $nav--main-font-weight;
  position: relative;
  width: 61em;
  
 
  a,
  span.active,
  span.trail,
  strong.active,
  strong.trail {
    padding: $nav--main__item-padding;
    text-transform: uppercase;
    text-decoration: none;
    
    @include media-query(screen-offcanvas) {
	    padding: calc((#{$header-height} - 1em) / 4) $nav--main__item-padding;
	    margin: calc((#{$header-height} - 1em) / 4) $nav--main__item-padding 0;
    }
    
    @if ($ie9-support == true) {
	    background-image: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7); // [1]
    }
  }
  
  a {
    color: #fff;
  }
  
  span.active,
  span.trail,
  strong.active,
  strong.trail {
    color: $color-navigation-main--active;
  }
}

// Logo center
 .nav--main ul li:nth-of-type(3){
 	margin: -0.6em 2em 0;
 	padding-left: 0;
 	padding-right: 0;
 	position: relative;
 	text-indent: -9999px;
 	z-index: 200;
 }

.nav--main ul li:nth-of-type(3) span,
.nav--main ul li:nth-of-type(3) strong {
	background: url("/files/bammatter/src/img/bammatter_logo.png") no-repeat center center;
	background-size: 200px auto;
	border-bottom: none;
	display: block;
	padding-bottom: 0;
	width: 200px;
	padding: 0.5em 0;
}

.nav--main ul li:nth-of-type(3) strong {
	margin-top: 1.1em;
	margin-left: 1.6em;
}

.nav--main ul li:nth-of-type(3){
	@include media-query(screen-offcanvas-max) {
		display: none;
	}
}

@media (max-width: 74em) { 
	.nav--main ul li:nth-of-type(3){
			margin: -0.6em 1em 0;
		}
	.nav--main a {
		padding-left: 0.7em;
		padding-right: 0.7em;
	}
}



// [1] transition for mobile navigation fade in background
// [2] transition for mobile navigation item moving
// [3] remove scrollbar for IE9 as it doesn't support -ms-overflow-style
.nav--mobile {
  @include media-query(screen-offcanvas-max) {
    max-height: 0;
    visibility: hidden;
    transition: max-height 0s 3s, background 0.3s; // [1]
    background: transparent; // [1]
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    z-index: -1;
    visibility: visible;
    font-size: 1.5rem;
    text-shadow: 0.025rem 0.025rem 2rem $nav-mobile-color-background;
    
    
    > ul {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      max-height: 100%;
      padding-top: $header-height;
      
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;
      -ms-touch-action: touch;
      -ms-overflow-style: none;
    }
    
    > ul > li {
    transition: transform 0.3s, opacity 0.3s; // [2]
    opacity: 0; // [2]
    
      @include media-query(screen-offcanvas) {
        opacity: 1;
      }
    }
    
    a {
	    color: $color-text--inverted;
    }
  }
  
  .ie9 & {
	  > ul {
		  overflow-y: auto;
	  }
  }
}

.nav--mobile--active {
  @include media-query(screen-offcanvas-max) {
    max-height: 100%;
    transition: max-height 0s, background 0.3s; // [1]
    background: $nav-mobile-color-background;
  
    >ul > li {
      transform: translateY(-#{$base-spacing-unit--sm});
      opacity: 1;
      
      @for $sec from 1 through $nav-mobile-number-of-menu-points {
        &:nth-child(#{$sec}) {
          transition-delay: #{$sec * 1 / 8}s;
        }
      }
    }
  }
}

// button to display mobile navigation
.nav-toggler {
  font-family: sans-serif;
  float: right;
  position: absolute;
  top: 0;
  right: $grid__gutter--half; 
  height: $header-height;

  @include media-query(screen-offcanvas) {
    display: none;  
  }   
}

.nav-toggler__button {
	@include vertical-align(50%);
  
  background: none;
  border: $base-border;
  outline: none; 
  font-weight: 700;
  display: inline-block;
  margin: 0;
  cursor: pointer;
  color: currentColor;
  padding: $base-spacing-unit--xs/2 $base-spacing-unit--xs;
}

.menu-icon {
	position: relative;
	width: $menu-icon-width;
	height: $base-line-height * 1rem;
	display: inline-block;
	vertical-align: top;
}

.menu-icon__inner {
	width: 100%;
	background-color: currentcolor;
	position: absolute;
	left: 0;
	top: 50%;
	margin-top: $menu-icon-stroke/-2;
  height: $menu-icon-stroke;
  transition: transform 0.15s ease;

	&:after, 
	&:before {
	  background-color: currentcolor;
	  position: absolute;
	  display: block;
		content: "";
    width: 100%;
		height: $menu-icon-stroke;
    transition: transform 0.15s ease, opacity 0.15s;
	}
	
	&:before {
		top: -6px;
	}
	
	&:after {
	  bottom: -6px;
	}
	
	.html--fixed & {
		transform: rotate(45deg);
		
		&:before {
			opacity: 0;
		}
		
		&:after {
			transform: rotate(-90deg);
			bottom: 0;
		}
	}
}

// nav Meta
.nav--meta {
  font-family: $nav--main-font;
  font-weight: $nav--main-font-weight;
  text-align: center;
  
  @include media-query(screen-offcanvas) {
  	float: left;
  }
  
  li {
	  display: inline-block;
  }
  
  a,
  span.active,
  span.trail,
  strong.active,
  strong.trail {
    padding: 0 $base-spacing-unit--sm;
    text-decoration: none;
    
    &.first {
      padding-left: 0;
    }
  }
}

.pagination {
  font-family: $base-font-family-sans-serif;
  font-weight: bold;
}

// nav Shop
.nav--shop {
  font-family: $nav--main-font;
  margin-bottom: $base-spacing-unit;
    
  ul {
	  max-width: 26.5rem;
	  margin: 0 auto;
	    		
  		li {
	  		float: left;
  		}
  }
  
  a {
    padding: 0 $base-spacing-unit--sm;
    text-decoration: none;
    color: $color-gray-dark;
    
    	/*&.first {
			padding-left: 0;
    	}
    
		&.last {
			padding-right: 0;
		}*/
    
		&:hover {
	    	color: $color-red;
    	}
  	}
  	
  	 span.active,
  	 span.trail,
  	 strong.active,
  	 strong.trail {
  	 	padding: 0 $base-spacing-unit--sm;
    }
    
     /*strong.active.first {
	     padding: 0 $base-spacing-unit--sm 0 0;
     }*/
}
