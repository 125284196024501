// *
// * Erdmann & Freunde - Nutshell
// * BASE Contao Framework
// * erdmann-freunde.de/nutshell/
// *
// * Lizenziert unter MIT OPEN SOURCE 
// *

//
// COMPONENTS/TABLES
// --------------------------------------------------

// VARIABLES
$table-border-width:      1px !default;
$table-border-style:      solid !default;
$table-border-color:      #ccc !default;
$table-background-light:  rgba(red($table-border-color), green($table-border-color), blue($table-border-color),0.2) !default;

// USED FROM _variables.scss

// --------------------------------------------------

table,
%table {
    margin-bottom: $base-spacing-unit;
    
    width: 100%;
    padding: 0;
    border-spacing: 0;
    border-left: $table-border-width $table-border-style $table-border-color;
    border-top: $table-border-width $table-border-style $table-border-color;

    th,
    td {
      border-right: $table-border-width $table-border-style $table-border-color;
      border-bottom: $table-border-width $table-border-style $table-border-color;

      padding: 10px;
    }
}

thead {
  text-align: left;
  background: $table-background-light;
}

tfoot {
  font-style: italic;
}

.table--striped {
  .even {
    background: $table-background-light;
  }
}

.table--overflow {
  overflow-x: scroll;
  -ms-overflow-x: auto;
}
