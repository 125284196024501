// *
// * JUMEDIA Webagentur GmbH
// * Bammatter Theme
// *

//
// COMPONENTS/NEWS
// --------------------------------------------------

// VARIABLES
$news-grid-columns--sm: 2;
$news-grid-columns--md: 2;
$news-grid-columns--lg: 3;

$news-hero-background-color: $color-band-dark-background;

// USED FROM _variables.scss
// $base-spacing-unit, --xs, --lg 
// $base-font-family-sans-serif
// 

// USED FROM _layout.scss
// $header-height

// USED FROM _grid.scss
// $grid__gutter--half

// USED FROM _type.scss
// $headings__font-family
// $headings__font-weight

// --------------------------------------------------

@import "../../../nutshell/scss/components/news";

// --------------------------------------------------
/*.news-grid .layout_latest.first,
.news-grid .layout_latest.even{
	width: 66.666%;
	
	@include media-query(screen-offcanvas-max) {
	width: 100%;
	max-width: 100%;
	height: auto;
	}
}

.news-grid .layout_latest.odd{
	width: 33.333%;
	@include media-query(screen-offcanvas-max) {
	width: 100%;
	max-width: 100%;
	height: auto;
	}
}*/

.news-grid .layout_latest.small{
	width: 33.333%;
	
	@include media-query(screen-offcanvas-max) {
	width: 100%;
	max-width: 100%;
	height: auto;
	}
}

.news-grid .layout_latest.big{
	width: 66.666%;
	@include media-query(screen-offcanvas-max) {
	width: 100%;
	max-width: 100%;
	height: auto;
	}
}*


.news-grid .layout_latest.all {
	margin-bottom: 4rem;
}


.news-grid .layout_latest {
	margin-top: 0;
}

.news-grid .layout_latest h2 {
	color: $color-gray-dark;
	font-size: 1rem;
	margin-bottom: 0!important;
}

.news-grid .layout_latest .teaser,
.news-grid .layout_latest_all .teaser {
	font-size: 0.8rem;
	font-family: $base-font-family;
}

.news-grid  .layout_latest .image_container {
	margin-bottom: 0.4rem;
	margin-top: 0;
}

.news-grid  .layout_latest img {
	max-width: none;
	width: auto; 
	height: auto;
	max-height: 477px;
	background-size: cover;
	
	@include media-query(screen-offcanvas-max) {
	max-width: 100%;
	width: 100%;
	height: auto;
	max-height: auto;
}
}


.image--news--small img{
	max-width: none;
	width: auto; 
	height: auto;
	max-height: 477px;
	background-size: cover;
}

// [1] IE11: remove uncommon space between image and headline
.news-grid {
  
  /*.layout_short,*/
  .layout_latest/*,
  .layout_simple*/ {
    
    #{headings()} {
      @extend %h3;
    
      a {
        color: inherit;
      }
    }
    
    &:after {
      border-bottom: $base-border;
      margin-top: $base-spacing-unit--sm;
      content: "";
      display: block;
    }
  }
  
  .image_container {
    margin-top: $base-spacing-unit;
    margin-bottom: $base-spacing-unit;
    float: none;
    overflow: hidden; // [1]
  }
  
  .image_container + h2 {
    margin-top: 0;
  }
  
  .info {
    padding-top: $base-spacing-unit--sm;
    font-size: 0.85em;
    font-weight: 700;
    font-family: $base-font-family-sans-serif;
    color: $color-gray;
  }
  
  .pagination {
    @include padding-default;
  }
  
  display: flex;
  flex-wrap: wrap;
  
  .layout_short,
  .layout_latest {
    display: flex;
    flex-direction: column;
  }
  
  .info {
    margin-top: auto;
  }
}


// News Full
.layout_full {
	margin-top: 4rem;
}
