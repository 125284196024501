// *
// * JUMEDIA Webagentur GmbH
// * Bammatter Theme
// *

//
// COMPONENTS/NOTES
// --------------------------------------------------

// VARIABLES

// USED FROM _variables.scss
// $base-font-family-sans-serif

// --------------------------------------------------
.footer__note {
  font-family: $base-font-family-sans-serif;
  font-size: 0.7rem;
  padding: 0 1rem;  
  
  span {
	    text-transform: uppercase;
  }
  
  .left {
	  float: left;
	}
	
	.left{
		@include media-query(screen-offcanvas-max) {
			float: left;
			display: block;
		}
	}
  
  .right {
	  float: right;  
   }
 
	.right{
		@include media-query(screen-offcanvas-max) {
		float: left;
		display: block;
		}
	}
}

.facebook img {
	margin-left: 0;
}
