// *
// * JUMEDIA Webagentur GmbH
// * Bammatter Theme
// *


//
// COMPONENTS/MEDIA
// --------------------------------------------------

// VARIABLES

// USED FROM _variables.scss
// $base-spacing-unit
// $base-img-shadow

// --------------------------------------------------

@import "../../../nutshell/scss/components/media";

// --------------------------------------------------

.ce_image {
  margin-bottom: $base-spacing-unit;
}

.ce_gallery {
  overflow: visible;
}

.ce_gallery .row_first.col_0.col_first,
.ce_gallery .row_last.col_last{
	width: 66.66%;
}

// [1] default class to create object-fit images
// [2] add by js for browsers that don't support the object-fit attribute
.image--object-fit .image_container { // [1]
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.compat-object-fit { // [2]
  background-size: cover;
  background-position: center center;
  
  img {
    opacity: 0;
  }
}

// fix for IE9: picture-element +  mediaelement.js + object-fit
@if($ie9-support == true) {
  .image_container .mejs-container {
    display: none;
  }
}

// image caption

.image {
  margin-bottom: $grid__gutter;
  font-weight: 700;
  font-family: $base-font-family-sans-serif;
  overflow: visible; // [1]
  
  .image_container {
    position: relative;
    
    a::before {
      @include vertical-align();  
      //content: attr(title);
      position: absolute;
      z-index: 1;
      color: #fff;
      text-align: center;
      width: 100%;
      opacity: 1;
      visibility: visible;
      transition: all 0.3s 0.1s;
       transform: translateY(-40%);
      padding: $base-spacing-unit;
      pointer-events: none; // [2]
    }
    
    a:after {
      content: "";
      display: block;
      vertical-align: middle;
      position: absolute;
      z-index: 1;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      visibility: visible;
      opacity: 1;
      transition: all 0.3s;
    }
    
    a {
      position: relative;
      z-index: 1;
      display: block;
    }
  }
  
  .caption { 
    text-align: center;
    width: 100%;
	font-family: $base-font-family-serif;
	text-transform: uppercase;
    padding: $base-spacing-unit--xs;
    font-size: 1.2em;
    
     @include media-query(screen-offcanvas-max) {
	  font-size: 1.3em;
  }
  }
  
  &:hover .image_container a:before,
  &:hover .image_container a:after {
    visibility: visible;
    opacity: 1;
  }
  
  &:hover .image_container a:before {
    transform: translateY(-40%);
  }
}

// [1] make caption also clickable if there is a link around image (not working in <=IE10)
.image--hover {
  
  .image_container {
    position: relative;
 }
  
  .caption { 
    @include vertical-align();
    position: absolute;
    z-index: 1;
    color: #fff;
    transition: all 0.3s 0.1s;
    padding: 0 $base-spacing-unit;
    pointer-events: none; // [1]
    top: 10%;
    transform: translateY(0);
  }
  
  //&:hover .image_container:after
  &:hover .image_container a:after 
  {
    background: $base-overlay-background;
  }

}

// ce_youtube
// contao 4.4, does no longer load youtube videos via mediaelement.js
// instead it uses an iframe. Nutshell Framework uses the .ce_youtube container
// to calculate the video ratio for responsiveness. Adding a Headline inside 
// .ce_youtube will cause wrong video dimensions
// [1] Warning not to use a Headline in Youtube
.ce_youtube {
  #{headings()} {
    position: absolute;
    z-index: 1001;
    background: $color-alert-bg;
    
    &:after {
	    content: " +++ Warning: do not use headlines inside ce_youtube, see _media.scss +++"; // [1]
    }
  }
}
