// *
// * JUMEDIA Webagentur GmbH
// * Bammatter Theme
// *

//
// COMPONENTS/SLIDER
// --------------------------------------------------

// VARIABLES
$arrow-color:               inherit;
$arrow-size:                32px;
$menu-point-color:          inherit;
$menu-point-color--active:  $color-brand;
$arrow-padding:							$base-spacing-unit--sm;

// USED FROM _variables.scss

// --------------------------------------------------

@import "../../../nutshell/scss/components/slider";

// --------------------------------------------------

.slider {
	.slider-wrapper > * {
		margin-bottom: $base-spacing-unit--lg;
	}
	
	.slider-control a {
		width: calc(#{$arrow-size} + #{$arrow-padding} * 2);
		height: calc(#{$arrow-size} + #{$arrow-padding} * 2);
		overflow: hidden;
	}
}

// Fullscreen Slider, used in combination with .mod_article.hero
.slider--hero {
  min-height: inherit;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  
  .content-slider,
  .slider-wrapper,
  .slider-wrapper > *,
  .image_container {
    min-height: inherit;
    
    .image_container {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
    
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
}
