// *
// * JUMEDIA Webagentur GmbH
// * Bammatter Theme
// *

//
// MIXINS/RESPONSIVE
// --------------------------------------------------

// VARIABLES
$wrapper-max:   60em; // ~ lg: 960px, xl: 1200px

// USED FROM _variables.scss

// --------------------------------------------------

@import "../../../nutshell/scss/mixins/responsive";

// --------------------------------------------------

// PUT ADDITONAL RESPONSIVE MIXINS HERE

$additional_breakpoints: (
  offcanvas: (
    min:        58.75em, // 768px
    max:        (58.75em - 0.0625em), // 767px
  )
) !default;

@include media-query(screen-offcanvas-max) {
table, thead, tfoot, tbody, tr, td, th {
  		display: block;
	}

	td[title]:before, td[data-header]:before {
  		content: attr(title);
  		display: block;
  		font-weight: bold;
	}
	
	th {
  		font-size: 120%;
  		font-weight: bold;
	}
	
	thead {
	  height: 0;
  	  overflow: hidden;
	}
	
		
	.product td.price span.currency::before {
		content: 'Einzelpreis';
		margin-right: 6px;
	}
	
	.product td.price.total span.currency::before {
		content: 'Gesamtpreis';
		margin-right: 6px;
		font-weight: 700;
	}
		.mod_iso_cart tbody tr td a.remove::before {
		content: 'Löschen';
		margin-right: 6px;
	}
	
	}